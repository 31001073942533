import Link from 'next/link';
import Head from 'next/head';
import { getPosts } from 'utils/blog/post';
// import Search from 'components/blog/Search';
import { adjustPostResponse, adapterWordpressLang } from 'utils/blog/helper';
import { getCategoryIDMap } from 'utils/blog/category';
import Breadcrumbs from 'components/blog/Breadcrumbs';
import PostList from 'components/blog/PostList';
import { useRouter } from 'next/router';

export default function Page({ posts }) {
  const router = useRouter();

  // if (router.locale === 'ru' || router.locale === 'de') {
  //   if (global && global.location) {
  //     global.location.href = '/blog';
  //   }
  //   return null;
  // }

  const options = posts.map((item) => {
    return {
      list: item.list,
      label: item.name,
      link: `/blog/${item.slug}`,
      id: item.id,
    };
  });

  return (
    <>
      <Head>
        <link
          rel="canonical"
          href={`https://airbrush.com/${router.locale === 'en' ? '' : `${router.locale}/`}blog`}
        />
      </Head>
      <div className="bg-white pt-[1px]">
        {/* <div className="mx-auto max-w-[1200px]"> */}
        <div className="mx-auto max-w-[1500px]">
          {/* banner */}
          <div className=" tex-left px-5 lg:px-10">
            <div className="mt-[30px] font-PoppinsBold text-3xl md:mt-[60px] md:text-[46px]">
              {$t({ defaultMessage: 'AirBrush Blog', id: 'Oef7xY' })}
            </div>
            <div className=" mt-5 text-lg text-[#323232]">
              {$t({
                defaultMessage:
                  'Tips, trends and tutorials to enhance your online presence and take your editing skills to the next level.',
                id: '1mibZy',
              })}
            </div>
          </div>

          {/* 操作栏 */}
          <div className="mt-5 flex flex-wrap items-center justify-between px-5 lg:px-10">
            <Breadcrumbs
              crumbs={[
                {
                  label: $t({ defaultMessage: 'Home', id: 'ejEGdx' }),
                  url: '/',
                },
                {
                  label: $t({ defaultMessage: 'Blog', id: 'tv5FG3' }),
                  url: '/blog',
                },
              ]}
            />
            {/* 移除搜索框  */}
            {/* <Search></Search> */}
          </div>

          <div className="mt-[80px] px-5 pb-[50px] md:mt-[50px] md:pb-[100px] lg:px-10">
            {options.map((option) => {
              return (
                <div className="mb-12" key={option.id}>
                  <div className="flex justify-between">
                    <div className="text-2xl font-semibold leading-7 lg:text-3xl lg:leading-9 ">
                      {option.label}
                    </div>
                    <Link
                      href={option.link}
                      className="text-lg font-normal leading-5 text-[#7C7C7C] lg:text-2xl lg:leading-7"
                    >
                      {$t({ defaultMessage: 'View all', id: 'pFK6bJ' })}
                    </Link>
                  </div>
                  <PostList list={option.list} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export const getStaticProps = async ({ locale }) => {
  const { tree } = await getCategoryIDMap({
    lang: adapterWordpressLang(locale),
  });

  const promises = [];
  Object.values(tree).forEach((node) => {
    promises.push(
      getPosts(node, {
        lang: adapterWordpressLang(locale),
        page: 1,
        per_page: 6,
        _fields:
          'id,date,content,acf,slug,title,link,categories,excerpt,sticky,_links.wp:featuredmedia',
        _embed: 'wp:featuredmedia',
      })
    );
  });

  const lists = await Promise.all(promises);
  const posts = lists
    .map((item) => {
      const detail = tree?.[item.categoryId];
      return {
        id: item.categoryId,
        name: detail?.name,
        slug: detail?.slug,
        list: adjustPostResponse(item.list),
      };
    })
    .filter((item) => item.list.length > 0);

  return { props: { posts } };
};
