import Link from 'next/link';
// import Image from 'next/image';
// import { useFetchCover } from 'utils/blog/helper';
import { useState, useEffect } from 'react';
// import styles from './styles.module.scss';

export default function PostList({
  list,
  additionalClass,
  titleColor,
}: {
  list: any[];
  additionalClass?: string;
  titleColor?: string;
}) {
  // const renderList = useFetchCover(list);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const displayedList = isMobile ? list.slice(0, 3) : list.slice(0, 6);

  return (
    <ul
      className={`${additionalClass} mt-6 grid grid-cols-1 gap-x-5 gap-y-6 sm:grid-cols-2 lg:grid-cols-3`}
    >
      {displayedList.map(({ id, title, slug, coverImg }) => (
        <li className="w-full" key={id}>
          <Link href={`/blog/${slug}`}>
            {coverImg ? (
              <div className="relative h-0 overflow-hidden pb-[56.33%]">
                <img
                  src={coverImg}
                  alt={title}
                  className="lazy-image absolute left-0 top-0 h-full w-full rounded-lg object-cover object-center"
                />
              </div>
            ) : (
              <div className="relative h-0 pb-[56.33%]" />
            )}
            <h2
              dangerouslySetInnerHTML={{
                __html: `${title}`,
              }}
              className={`mt-[18px] w-full text-xl font-semibold leading-6 text-[#333] ${titleColor}`}
            />
          </Link>
        </li>
      ))}
    </ul>
  );
}
