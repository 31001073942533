import clsx from 'clsx';
import Link from 'next/link';

type TypeCrumbs = {
  label: string;
  url: string;
};

export default function Breadcrumbs({ crumbs }: { crumbs: Array<TypeCrumbs> }) {
  return (
    <>
      <div className="flex flex-wrap items-center text-sm text-black/40 ">
        {crumbs.map(({ label, url }, index) => {
          const isTail = index + 1 === crumbs?.length;
          const eleProp = {
            href: url,
            key: label,
            className: clsx('truncate block max-w-[200px]', { 'text-black/80': isTail }),
          };
          return (
            <>
              {/* {url ? <Link {...eleProp}>{label}</Link> : <span {...eleProp}>{label}</span>} */}
              {url ? <Link {...eleProp}>{label}</Link> : null}
              {!isTail && <span className="mx-1">/</span>}
            </>
          );
        })}
      </div>
    </>
  );
}
